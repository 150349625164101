import { BaseDto } from "../baseDto"
/* IpAddressRangeLocationBaseDto generated at 2024-12-06 13:53:49.511 +11:00*/
export class IpAddressRangeLocationBaseDto extends BaseDto {
	IpAddressRangeLocationId?: string;
	FromIpRange?: number;
	ToIpRange?: number;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	CountryName?: string;
	LatitudeValue?: number;
	LongitudeValue?: number;
	TimeOffset?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	StatusCode?: string;
}
