export const environment = {
  EnvironmentCode: "Test",
  TokenValidityInMinutes: 3600,
  ApiUrl: "https://test.arl.org.au/api",
  LoginAction: "/Authentication/Login",
  RefreshTokenAction: "/Authentication/RefreshToken",
  CheckAuthenticationAction: "/Authentication/CheckAuthentication",
  LogOutAction: "/Authentication/LogOut",
  SignalService: "https://test.arl.org.au/appHub",
  SignalHubName: "appHub",
  BasecampSSOUrl: "https://launchpad.37signals.com/authorization/new?client_id=8a2de46b0c1bb07441ccd87bb3655f2b1a17531a&redirect_uri=https://test.arl.org.au/api/Authentication/ANZPACAuth&type=web_server",
  RMUrl: "https://app-uat.recyclemate.com.au/sitelet/2"
};
