import { BaseDto } from "../baseDto"
/* WARLResourceBaseDto generated at 2024-12-06 13:53:49.793 +11:00*/
export class WARLResourceBaseDto extends BaseDto {
	WebARLResourceId?: string;
	ResourceName?: string;
	ResourceDescription?: string;
	ImageURL?: string;
	ResourceURL?: string;
	Sequence?: number;
	StatusCode?: string;
}
