import { BaseDto } from "../baseDto"
/* IPAccessBaseDto generated at 2024-12-06 13:53:49.509 +11:00*/
export class IPAccessBaseDto extends BaseDto {
	IPAccessId?: string;
	IPAccessTypeCode?: string;
	UserId?: string;
	IPAddress?: string;
	AccessStatusCode?: string;
	EnvCode?: string;
	StatusCode?: string;
}
