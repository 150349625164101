import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { UserDto } from 'src/app/core/model/feature/apcomodel';
import { ActionResult } from 'src/app/core/model/shared';
@Component({
    selector: "auth-forgot-password",
    templateUrl: "./forgot-password.component.html"
})
export class AuthForgotPasswordCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    delayRequestTimer: any = null;
    showRequestReset: boolean = true;
    showSuccess: boolean = false;
    showError: boolean = false;
    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();
    @Output() onCancel = new EventEmitter<any>();
    user: UserDto = new UserDto();

    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
            // this.navigationSubscription = this.appContext.onNavigationEnd().subscribe((e: any) => {
            //     if (this.showComponent) {
            //         this.loadInitData();
            //     }
            // });
        } catch (ex) {

            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {

            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {

            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {

            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);

        }
    }
    initPage() {
        try {
            this.resetAll();
            this.showRequestReset = true;
            this.showComponent = true;
        } catch (ex) {

            console.error(ex);
        }
    }
    get isValid() {
        let lvValid: any = false;
        try {
            lvValid = this.thisForm && this.thisForm.form.valid;
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    resetAll() {
        this.showRequestReset = false;
        this.showSuccess = false;
    }
    onRequest() {
        try {
            let that = this;
            this.delayRequestTimer = setTimeout(function () {
                if (that.delayRequestTimer) { clearTimeout(that.delayRequestTimer); }
                that.delayRequestTimer = undefined;
                that.executeRequest();
            }, 1000);
        } catch (ex) {
            console.error(ex);
        }
    }
    executeRequest() {
        try {
            if (this.user.Username) {
                this.authSvc.forgotPassword(this.user.Username).subscribe((result: ActionResult) => {
                    this.resetAll();
                    if (result) {
                        this.showSuccess = true;
                    }
                    else {
                        this.showError = true;
                    }
                }, (error: any) => {
                    console.error("Request password reset error", error);
                    this.resetAll();
                    this.showError = true;
                });
            } else {
                this.resetAll();
                this.showRequestReset = true;
            }
        } catch (ex) {
            console.error(ex);
            this.resetAll();
            this.showError = true;
        }
    }
    onTryAgain() {
        this.resetAll();
    }
    onCancelEvent() {
        this.resetAll();
        this.showRequestReset = true;
        this.visible = false;
        this.onCancel.emit();
    }
    onCloseEvent() {
        this.resetAll();
        this.showRequestReset = true;
        this.visible = false;
        this.onClose.emit();
    }
}