import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { NgModule } from "@angular/core";
const routes: Routes = [
	{ path: "anzpac", loadChildren: () => import("./features/anzpac-module/anzpac.module").then(m => m.AANZPACModule) },
	{ path: "admin", loadChildren: () => import("./features/config-module/config.module").then(m => m.ConfigModule) },	
	{ path: "member", loadChildren: () => import("./features/member-module/member.module").then(m => m.MemberModule) },	
	{ path: "", loadChildren: () => import("./features/home-module/home.module").then(m => m.HomeModule) },
	{ path: "**", redirectTo: "" }
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			enableTracing: false,
			onSameUrlNavigation: "reload",
			// Don't perform initial navigation in iframes
			//initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
			preloadingStrategy: PreloadAllModules
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
