import { BaseDto } from "../baseDto"
/* InboundDataArchiveBaseDto generated at 2024-12-06 13:53:49.501 +11:00*/
export class InboundDataArchiveBaseDto extends BaseDto {
	InboundDataArchiveId?: string;
	InboundDataId?: string;
	ARLFileId?: string;
	DataClassificationCode?: string;
	ReferenceId1?: string;
	ReferenceId2?: string;
	ReferenceId3?: string;
	SequenceNo?: number;
	FileData?: string;
	ValidationErrors?: string;
	ErrorMessage?: string;
	DateProcessed?: Date;
	StatusCode?: string;
}
