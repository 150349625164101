import { BaseDto } from "../baseDto"
/* EmailFileBaseDto generated at 2024-12-06 13:53:49.459 +11:00*/
export class EmailFileBaseDto extends BaseDto {
	FileId?: string;
	RegisteredFileId?: string;
	EmailMessageId?: string;
	Sequence?: number;
	Filename?: string;
	External_ReferenceId?: string;
	StatusCode?: string;
}
