import { UserBaseDto } from "../../base/apcomodel";
import { UserRoleDto } from "./UserRoleDto";

export class UserDto extends UserBaseDto {
    Password?: string;
    NewPassword?: string;
    UserRoles?: UserRoleDto[];   
    DefaultOrganisationName?: string;
    CurrentOrganisationName?: string; 
    VerificationCode?: string; 
    RequestCode?: string; 
}