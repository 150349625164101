<div class="flex-wrap" *ngIf="showComponent && visible">
    <div class="flex-wrap">
        <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
            <b>Forgot Password</b>
        </div>
    </div>
    <div class="flex-wrap p2" *ngIf="showRequestReset">
        <span>Enter the email address to reset password.</span>
    </div>
    <div class="flex-wrap mt-2">
        <form name="form" class="flex-wrap" (ngSubmit)="onRequest()" #thisForm="ngForm" autocomplete="off" novalidate>
            <div class="flex-wrap p2" *ngIf="showRequestReset">
                <div class="flex-wrap">
                    <label style="color:red;">* Email</label>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="resetUsername" style="width: 100%;" type="text" [(ngModel)]="user.Username"
                        required pInputText validateEmail />
                </div>
                <div class="flex-wrap mt-2">
                    <span style="color:red;">* denotes required details.</span>
                </div>
            </div>
            <div class="flex-wrap p2" *ngIf="showSuccess">
                <div class="flex-wrap">                    
                    <span class="center">Your request is being processed. You will receive an email providing the link to reset the password.</span>
                </div>
            </div>
            <div class="flex-wrap p2" *ngIf="showError">
                <div class="flex-wrap">                    
                    <span class="center">An error occured while requesting for reset password.</span>
                </div>
            </div>
            <div class="flex-wrap" *ngIf="showRequestReset">
                <div class="flex-wrap p2">
                    <p-button type="submit" class="flex-wrap button button-highlight button-block center" [disabled]="!isValid"
                    (click)="onRequest()">
                    <span class="center">Reset Password</span>
                </p-button>
                </div>
                <div class="flex-wrap p2">
                    <p-button type="button" class="flex-wrap button button-highlight button-block center" (click)="onCancelEvent()">
                        <span class="center">Cancel</span>
                    </p-button>
                </div>
            </div>
            <div class="flex-wrap" *ngIf="showSuccess||showError">
                <div class="flex-wrap p2">
                    <p-button type="button" class="flex-wrap button button-highlight button-block center" (click)="onCloseEvent()">
                        <span class="center">Close</span>
                    </p-button>
                </div>
            </div>
        </form>
    </div>
</div>