import { BaseDto } from "../baseDto"
/* OrganisationActivityDetailBaseDto generated at 2024-12-06 13:53:49.544 +11:00*/
export class OrganisationActivityDetailBaseDto extends BaseDto {
	OrganisationActivityDetailId?: string;
	OrganisationActivityId?: string;
	OrganisationId?: string;
	ActivityDate?: Date;
	ActivityType?: string;
	ReferenceId?: string;
	ProjectId?: string;
	NoOfCmpnt?: number;
	PREP_ProjectId?: number;
	ProjectStatus?: string;
	ActionStatus?: string;
}
