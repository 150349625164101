import { Injectable, NgZone } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
//import { MessageService } from 'primeng/api';
import { ActionResult, ColumnFilter } from '../../model/shared';
import { Observable, Subject } from "rxjs";
import { ValidationConstants } from "../../model/shared/validation-constants";
import { MessageService } from "primeng/api";

declare var window: any;
@Injectable()
export class SharedService {	
	constructor(
		private messageService: MessageService,
		private ngZone: NgZone,
		private router: Router) { }
        
	navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		return this.router.navigate(commands, extras);
	}

	navigateByUrl(url: string, extras?: NavigationExtras): Promise<boolean> {
		return this.router.navigateByUrl(url, extras);
	}
	isEmail(value: string) {
		let emailType = new RegExp(ValidationConstants.emailRegExp);
		return emailType.test(value);
	}
	isPhoneNumber(value: string) {
		if (!value || value === null || value === '') {
			return true;
		}
		let phoneNumberType = new RegExp(ValidationConstants.phoneNumberRegExp);
		return phoneNumberType.test(value);
	}
	isPassword(value: string) {
		let passwordType = new RegExp(ValidationConstants.passwordRegExp);
		return passwordType.test(value);
	}
	isNumber(value: string) {
		let numberType = new RegExp(ValidationConstants.numberRegExp);
		return numberType.test(value)
	}
	addFilter(theFilters: ColumnFilter[], theFilter: ColumnFilter): ColumnFilter[] {
		if (theFilters && theFilter) {
			theFilters = theFilters.filter(x => x.Name !== theFilter.Name);
			theFilters.push(theFilter);
		}
		return theFilters;
	}
	showSucesss(message: string, summary: string = "") {
		try {
			this.messageService.add({ severity: "success", summary: summary, detail: message, sticky: false });
		} catch (e) {
			console.error(e);
		}
	}
	showWarning(message: string, summary: string = "") {
		try {
			this.messageService.add({ severity: "warn", summary: summary, detail: message, sticky: true });
		} catch (e) {
			console.error(e);
		}
	}
	showInfo(message: string, summary: string = "") {
		try {
			this.messageService.add({ severity: "info", summary: summary, detail: message, sticky: false });
		} catch (e) {
			console.error(e);
		}
	}
	showError(error: any, summary: string = "") {
		try {
			if (error && typeof (error.IsSuccess) !== "undefined") {
				if (!error.IsSuccess && error.Messages) {
					this.showActionResult(error);
					return;
				}
			}
			let errorMessage = "";
			if (error && error.error && error.error.Messages && error.error.Messages.length) {
				this.showErrors(error.error.Messages);
			} else if (error && error.error && error.error.error_description) {
				errorMessage = error.error.error_description;
			} else if (error && error["responseJSON"] && error["responseJSON"].error_description) {
				errorMessage = error["responseJSON"].error_description;
			} else if (error && error.message) {
				errorMessage = error.message;
			} else if (error && error.error && error.error.FullExceptionMessage) {
				errorMessage = error.error.FullExceptionMessage;
			} else if (error) {
				errorMessage = error;
			}
      if (errorMessage) {
        this.messageService.clear();
        this.messageService.add({ severity: "error", summary: summary, detail: errorMessage, sticky: true });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);

			}
		} catch (e) {
			console.error(e);
		}
	}
	showErrors(messages: any[], summary: string = "") {
		try {
			this.messageService.addAll(messages.map(m => {
				if (m.Message) {
					return { severity: "error", summary: summary, detail: m.Message, sticky: true };
				} else {
					return { severity: "error", summary: summary, detail: m, sticky: true };
				}
			}));
		} catch (ex) { }
	}
	showActionResult(actionResult: ActionResult, summary: string = "") {
		try {
			let severity = actionResult.IsSuccess ? "success" : "error";
			let tMsg: any;
			try {
				if (actionResult.Messages && actionResult.Messages.length === 1) {
					tMsg = JSON.parse(actionResult.Messages[0].Message);
					if (!(tMsg && tMsg.Messages)) {
						tMsg = actionResult;
					}
				} else {
					tMsg = actionResult;
				}
			} catch (e) {
				tMsg = actionResult;
			}
			// this.messageService.addAll(tMsg.Messages.map((m: { MessageType: any; Message: any; }) => {
			// 	let sev = "info";
			// 	let sticky = false;
			// 	switch (m.MessageType) {
			// 		case "Error":
			// 			sev = "error";
			// 			sticky = true;
			// 			break;
			// 		case "ValidationError":
			// 			sev = "error";
			// 			sticky = true;
			// 			break;
			// 		case "Warning":
			// 			sev = "warn";
			// 			sticky = true;
			// 			break;
			// 		case "Notification":
			// 			sev = "info";
			// 			break;
			// 		case "Success":
			// 			sev = "success";
			// 			break;
			// 	}
			// 	return { severity: severity, summary: summary, detail: m.Message, sticky: sticky };
			// }));
		} catch (e) {
			console.error(e);
		}
	}

	//#endregion
	replaceHref(href: string) {
		let theHref = href.replace("https://arl.org.au", "");
		theHref = theHref.replace("http://arl.org.au", "");
		theHref = theHref.replace("https://stage.arl.org.au", "");
		theHref = theHref.replace("http://stage.arl.org.au", "");		
		theHref = theHref.replace("https://test.arl.org.au", "");
		theHref = theHref.replace("http://test.arl.org.au", "");		
		theHref = theHref.replace("https://dev.arl.org.au", "");
		theHref = theHref.replace("http://dev.arl.org.au", "");
		theHref = theHref.replace("http://localhost:4200", "");
		theHref = theHref.replace("http://", "");
		theHref = theHref.replace("ms-appx-web://au.org.arl/www", "");
		theHref = theHref.replace("ms-appx://au.org.arl/www", "");
		theHref = theHref.replace("file:///android_asset/www", "");
		return theHref;
  }

  private notificationSubject = new Subject<string>();

  setNotification(notification: string)
  {
    this.notificationSubject.next(notification);
  }
  getNotification()
  {
    return this.notificationSubject.asObservable();
  }
  
  private scrollBarNotificationSubject = new Subject<string>();

  setScrollbar(notification: string) {
    this.scrollBarNotificationSubject.next(notification);
  }
  getScrollbar() {
    return this.scrollBarNotificationSubject.asObservable();
  }
}
