import { BaseDto } from "../baseDto"
/* UserBaseDto generated at 2024-12-06 13:53:49.781 +11:00*/
export class UserBaseDto extends BaseDto {
	UserId?: string;
	Username?: string;
	FirstName?: string;
	LastName?: string;
	DisplayName?: string;
	Phone?: string;
	Mobile?: string;
	Email?: string;
	SecondaryEmail?: string;
	IsUserConfirmed?: boolean;
	IsMobileVerified?: boolean;
	PendingMobile?: string;
	OriginalMobile?: string;
	IsEmailVerified?: boolean;
	PendingEmail?: string;
	OriginalEmail?: string;
	IsSecondaryEmailVerified?: boolean;
	PendingSecondaryEmail?: string;
	OriginalSecondaryEmail?: string;
	RequiresPasswordChange?: boolean;
	DatePasswordChangeExpiry?: Date;
	UserPassword?: string;
	PasswordSalt?: string;
	RequiresInfoUpdate?: boolean;
	SourceSystemCode?: string;
	DefaultOrganisationId?: string;
	CurrentOrganisationId?: string;
	CurrentEnvironmentCode?: string;
	StatusCode?: string;
}
