import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { Globals } from "../../../../globals";
import { NgForm } from "@angular/forms";
import { UserDto } from "src/app/core/model/feature/apcomodel";
@Component({
    selector: "auth-signin",
    templateUrl: "./signin.component.html"
})
export class AuthSignInCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;

    user: UserDto = new UserDto({ Username: "", UserPassword: "", FirstName: "", Surname: "", DisplayName: "" });    
    showSignIn: boolean = true;
    showChangePassword: boolean = false;
    showForgotPassword: boolean = false;
    
    @Input() visible: boolean = false;
    @Input() InvitationId: string | undefined;
    @Input() InvitationKey: string | undefined;
    @Output() onSignInReturn = new EventEmitter<boolean>();
    @Output() onSignInCancel = new EventEmitter<boolean>();

    constructor(public override globals: Globals, appContext: AppContextService) {        
        super(appContext);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.initUser();
            this.showComponent = true;
        } catch (ex) {
            console.error(ex);
        }
    }
    initUser() {
        this.user.Username = "";
        this.user.UserPassword = "";
        this.user.DisplayName = "";
    }
    onLocalSignInSuccessEvent(event: UserDto){
        this.onSignInReturn.emit(true);
    }
    onLocalSignInFailedEvent(event:any){
        this.sharedSvc.showError(event.error);
    }
    onLocalSignInCancelEvent(){
        this.onSignInCancel.emit();
    }
    onLocalSignInForgotPassword(){
        this.showSignIn = false;
        this.showForgotPassword = true;
    }
    onForgotPasswordCancelEvent(){
        this.showSignIn = true;
        this.showForgotPassword = false;
    }
    onForgotPasswordCloseEvent(){
        this.onSignInCancel.emit();
    }
}