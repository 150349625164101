import { BaseDto } from "../baseDto"
/* SystemModelBaseDto generated at 2024-12-06 13:53:49.769 +11:00*/
export class SystemModelBaseDto extends BaseDto {
	SystemModelId?: string;
	ModelName?: string;
	ModelTypeCode?: string;
	IsOrgDb?: boolean;
	OrgDbVersion?: number;
	SchemaJSON?: string;
	StatusCode?: string;
}
