import { BaseDto } from "../baseDto"
/* SFContactBaseDto generated at 2024-12-06 13:53:49.694 +11:00*/
export class SFContactBaseDto extends BaseDto {
	Id?: string;
	IsDeleted?: boolean;
	MasterRecordId?: string;
	AccountId?: string;
	LastName?: string;
	FirstName?: string;
	Salutation?: string;
	Name?: string;
	OtherStreet?: string;
	OtherCity?: string;
	OtherState?: string;
	OtherPostalCode?: string;
	OtherCountry?: string;
	OtherLatitude?: number;
	OtherLongitude?: number;
	OtherGeocodeAccuracy?: string;
	MailingStreet?: string;
	MailingCity?: string;
	MailingState?: string;
	MailingPostalCode?: string;
	MailingCountry?: string;
	MailingLatitude?: number;
	MailingLongitude?: number;
	MailingGeocodeAccuracy?: string;
	Phone?: string;
	Fax?: string;
	MobilePhone?: string;
	HomePhone?: string;
	OtherPhone?: string;
	AssistantPhone?: string;
	ReportsToId?: string;
	Email?: string;
	Title?: string;
	Department?: string;
	AssistantName?: string;
	LeadSource?: string;
	Birthdate?: Date;
	Description?: string;
	OwnerId?: string;
	HasOptedOutOfEmail?: boolean;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	LastActivityDate?: Date;
	LastCURequestDate?: Date;
	LastCUUpdateDate?: Date;
	LastViewedDate?: Date;
	LastReferencedDate?: Date;
	EmailBouncedReason?: string;
	EmailBouncedDate?: Date;
	IsEmailBounced?: boolean;
	PhotoUrl?: string;
	Jigsaw?: string;
	JigsawContactId?: string;
	IndividualId?: string;
	IsPriorityRecord?: boolean;
	Avatar_Picture__c?: string;
	Competency_for_PREP__c?: boolean;
	Consulting_Partner__c?: boolean;
	Current_Sign_in_At__c?: Date;
	Current_Sign_in_IP__c?: string;
	Encrypted_Password__c?: string;
	Events_And_Webinars__c?: boolean;
	External_ID__c?: string;
	Invitation_Accepted_At__c?: Date;
	Invitation_Created_At__c?: Date;
	Invitation_Token__c?: string;
	Last_Sign_in_At__c?: Date;
	Last_Sign_in_IP__c?: string;
	Member_Obligations__c?: boolean;
	Monthly_Member_Update__c?: boolean;
	News_And_Media__c?: boolean;
	Opportunities_And_Grants__c?: boolean;
	Remember_Created_At__c?: Date;
	Reset_Password_Sent_At__c?: Date;
	Reset_Password_Token__c?: string;
	Resources_Reports_And_Program_Updates__c?: boolean;
	Sign_in_Count__c?: number;
	Signed_Up_For_Newsletter__c?: boolean;
	Type__c?: string;
	Temp_Sign_Up_URL__c?: string;
	Interim_Coassemble_Password__c?: string;
	Coassemble_Member_ID__c?: string;
	Send_Member_Center_Invitation__c?: boolean;
	pi__Needs_Score_Synced__c?: boolean;
	pi__Pardot_Last_Scored_At__c?: Date;
	pi__campaign__c?: string;
	pi__comments__c?: string;
	pi__conversion_date__c?: Date;
	pi__conversion_object_name__c?: string;
	pi__conversion_object_type__c?: string;
	pi__created_date__c?: Date;
	pi__first_activity__c?: Date;
	pi__first_search_term__c?: string;
	pi__first_search_type__c?: string;
	pi__first_touch_url__c?: string;
	pi__grade__c?: string;
	pi__last_activity__c?: Date;
	pi__notes__c?: string;
	pi__pardot_hard_bounced__c?: boolean;
	pi__score__c?: number;
	pi__url__c?: string;
	pi__utm_campaign__c?: string;
	pi__utm_content__c?: string;
	pi__utm_medium__c?: string;
	pi__utm_source__c?: string;
	pi__utm_term__c?: string;
	Stakeholder_List__c?: boolean;
	Member_Center_Interim_Password__c?: string;
	Comms_Pref_Support_field__c?: boolean;
	Pardot_Archive__c?: boolean;
	PREP_ARL_renew_date__c?: Date;
	Account_name_Temporary__c?: string;
	Competency_for_ARL__c?: boolean;
	ARL_Competency_Completion_Date__c?: Date;
	PREP_Competency_Completion_Date__c?: Date;
	ARLMP_Heroku_ID__c?: string;
	ARLMP_Encrypted_Password__c?: string;
	ARL_T_Cs_Renew_Date__c?: Date;
}
