import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "./../../../../core/component";
import { AppContextService } from "./../../../../core/service";
import { Constants, UserDto } from 'src/app/core/model/feature/apcomodel';
import { DataResult } from 'src/app/core/model/shared';
//import { AppMessages, UserDto } from './../../../../core/model';
@Component({
    selector: "auth-reset-password",
    templateUrl: "./reset-password.component.html"
})
export class AuthResetPasswordCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    delayRequestTimer: any = null;

    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    @Input() email: string = "";
    @Input() requestcode: string = "";
    @Input() dateExpiry?: Date;
    @Output() onResetPasswordAgain = new EventEmitter<any>();

    showResetPassword: boolean = false;
    showEnterVerificationCode: boolean = false;
    showSuccess: boolean = false;
    showError: boolean = false;
    showPassword: boolean = false;
    user: UserDto = new UserDto();

    constructor(appContext: AppContextService) {
        super(appContext);
        try {
            // this.navigationSubscription = this.appContext.onNavigationEnd().subscribe((e: any) => {
            //     if (this.showComponent) {
            //         this.loadInitData();
            //     }
            // });
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.user = new UserDto();
            if (this.email) {
                this.user.Username = this.email;
            }
            if (this.requestcode) {
                this.user.RequestCode = this.requestcode;
                this.resetAll();
                this.showResetPassword = true;
                this.initPage();
            } else {
                this.resetAll();
                this.showResetPassword = true;
                this.initPage();
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            console.error(ex);
        }
    }
    get isValid() {
        let lvValid: any = false;
        try {
            lvValid = this.thisForm && this.thisForm.form.valid;
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    resetAll() {
        this.showResetPassword = false;
        this.showEnterVerificationCode = false;
        this.showSuccess = false;
        this.showError = false;
    }
    onRequestForVerificationCode() {
        try {
            let that = this;
            this.delayRequestTimer = setTimeout(function () {
                if (that.delayRequestTimer) { clearTimeout(that.delayRequestTimer); }
                that.delayRequestTimer = undefined;
                that.executeRequestForVerificationCode();
            }, 1000);
        } catch (ex) {
            console.error(ex);
        }
    }
    executeRequestForVerificationCode() {
        try {
            this.authSvc.generateAuthCode(this.user.Username, Constants.AuthorisationType.AuthorisationCode).subscribe(result => {
                this.resetAll();
                if (result.IsSuccess) {
                    this.showEnterVerificationCode = true;
                } else {
                    this.showError = true;
                }
            }, error => {
                console.error("Request for verification code error", error);
                this.resetAll();
                this.showError = true;
            });
        } catch (ex) {
            console.error(ex);
            this.resetAll();
            this.showError = true;
        }
    }
    onRequest() {
        try {
            if (this.user.VerificationCode) {
                let that = this;
                this.delayRequestTimer = setTimeout(function () {
                    if (that.delayRequestTimer) { clearTimeout(that.delayRequestTimer); }
                    that.delayRequestTimer = undefined;
                    that.executeRequest();
                }, 1000);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    executeRequest() {
        try {
            this.authSvc.resetPassword(this.user).subscribe(result => {
                this.resetAll();
                if (result) {
                    this.showSuccess = true;
                }
                else {
                    this.showError = true;
                }
            }, error => {
                console.error("Request to reset password error", error);
                this.resetAll();
                this.showError = true;
            });
        } catch (ex) {
            console.error(ex);
            this.resetAll();
            this.showError = true;
        }
    }
    isUsernameDisabled() {
        let lvValid: any = true;
        try {
            if (this.email) {
                lvValid = true;
            } else {
                lvValid = false;
            }
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    onTryAgain() {
        this.resetAll();
        this.visible = false;
        this.onResetPasswordAgain.emit();
    }
    onCancel() {
        this.resetAll();
        this.visible = false;
        this.onClose.emit();
    }
}