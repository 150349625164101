<p-dialog header="Change Password" [appendTo]="'body'" [visible]="showComponent && visible" (visibleChange)="showComponent && (visible = $event)"
    [contentStyle]="{'max-width':'40em','max-height':'calc(100vh)','overflow':'auto'}" [closable]="true" (onHide)="onDialogClose($event)"
    [closeOnEscape]="true" [modal]="true">
    <div class="page-content-container">
        <auth-change-password name="changePasswordCmpnt" #changePasswordCmpnt class="page-content-container" 
        [visible]="showComponent && visible" (onSuccess)="onDialogSuccess($event)"
        (onFailed)="onDialogFailed($event)" (onClose)="onDialogClose($event)"
        [user]="user" [showCurrentPassword]="showCurrentPassword">
        </auth-change-password>
    </div>
</p-dialog>