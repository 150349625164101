import { BaseDto } from "../baseDto"
/* ProjectAttributeBaseDto generated at 2024-12-06 13:53:49.621 +11:00*/
export class ProjectAttributeBaseDto extends BaseDto {
	ProjectAttributeId?: string;
	ProjectId?: string;
	ProjectItemId?: string;
	ProjectItemGroupId?: string;
	ObjectAttributeVersionId?: string;
	AttributeJSON?: string;
	StatusCode?: string;
}
