import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActionResult, ApplicationInfo, DataResult, FileDto } from '../../model/shared';
import { UtilityService } from './utility.service';
import { environment } from 'src/environments/environment';
import { DomService } from './dom.service';
import { UserDto } from '../../model/feature/apcomodel/UserDto';

@Injectable()
export class ApiService {
	private utilSvc: UtilityService = new UtilityService();
	private appInfo: ApplicationInfo = new ApplicationInfo();
	private domSvc: DomService = new DomService();
	constructor(private httpClient: HttpClient) {
		this.appInfo = environment;
	}
	private processRequestError(error: any) {
		if (error.error instanceof Error) {
			//console.error('An error occurred:', error.error.message);
		} else {
			//console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
		}
		if (error && error.error && error.error.error_description) {
			error["ErrorDetail"] = error.error.error_description;
			if (error["Messages"] && error["Messages"].length) {
				error["Messages"].push({ Message: error.error.error_description });
			} else {
				error["Messages"] = [{ Message: error.error.error_description }];
			}
		}
		if (error && error.error && error.error.Message) {
			if (error["Messages"] && error["Messages"].length) {
				error["Messages"].push({ Message: error.error.Message });
			} else {
				error["Messages"] = [{ Message: error.error.Message }];
			}
		}
		return error;
	}
	private processActionResult(resp: any): any {
		resp = this.utilSvc.parseSubJsonFields(resp);
		this.utilSvc.fixDates(resp, "", true);
		if (typeof (resp["IsSuccess"]) === "undefined") {
			resp["IsSuccess"] = true;
		} else {
			if (resp["IsSuccess"]) {
				resp["IsSuccess"] = true;
			} else {
				resp["IsSuccess"] = false;
			}
		}
		return resp;
	}
	private setHeaders(addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = ""
		, formData: boolean = false, skipContentType: boolean = false): HttpHeaders {
		let headersConfig: any = {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Credentials": "false",
			"Accept": (formData) ? "*/*" : "application/json",
			"Cache-Control": "no-cache",
			"timeout": (formData) ? "300000" : "180000"
		};
		if (!skipContentType) {
			headersConfig["Content-Type"] = (formData) ? "application/x-www-form-urlencoded; charset=UTF-8" : "application/json";
		}
		if (!skipAuthorisation) {
			if (userId && localStorage['Authorisation']) {
				headersConfig.Authorization = 'Bearer ' + localStorage['Authorisation'];
				headersConfig["Access-Control-Allow-Credentials"] = "true";
			} else {
				if (localStorage['Authorisation']) {
					headersConfig.Authorization = 'Bearer ' + localStorage['Authorisation'];
					headersConfig["Access-Control-Allow-Credentials"] = "true";
				}
			}
		}
		if (addHeaders) {
			headersConfig = Object.assign(headersConfig, addHeaders);
		}
		return headersConfig;
	}
	get(path: string, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				this.actualGet(`${this.appInfo.ApiUrl}${path}`, params, addHeaders, skipAuthorisation, userId).subscribe(result => {
					observer.next(result);
				}, getError => {
					observer.error(getError);
				});
			} catch (ex) {
				console.log("HttpGet error", ex);
				observer.error(ex);
			}
		});
	}
	getFile(path: string, params: HttpParams = new HttpParams()): Observable<FileDto> {
		return new Observable(observer => {
			this.httpClient.get(`${this.appInfo.ApiUrl}${path}`, { headers: this.setHeaders(), observe: 'response', responseType: 'blob', params: params }).subscribe((resp: any) => {
				try {
					const keys = resp.headers.keys();
					let contentDisposition = resp.headers.get('Content-Disposition');
					let filename = "";
					if (contentDisposition) {
						let splitCD = contentDisposition.split("filename=");
						if (splitCD && splitCD.length > 0) {
							filename = splitCD[splitCD.length - 1].replaceAll("\"", "");
						}
					}
					let fileDto: FileDto = new FileDto();
					fileDto.Headers = keys.map((key: any) => `${key}: ${resp.headers.get(key)}`);
					fileDto.Filename = filename;
					fileDto.BlobContent = resp.body;
					observer.next(fileDto);
				} catch (actionError) {
					observer.error(actionError);
				}
			}, (error: HttpErrorResponse) => {
				observer.error(error);
			});
		});
	}
	postGetFile(path: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<Blob> {
		return new Observable(observer => {
			this.httpClient.post(`${this.appInfo.ApiUrl}${path}`, body, { headers: this.setHeaders(), responseType: 'blob', params: params }).subscribe((resp: Blob) => {
				try {
					observer.next(resp);
				} catch (actionError) {
					observer.error(actionError);
				}
			}, (error: HttpErrorResponse) => {
				observer.error(error);
			});
		});
	}
	private actualGet(path: string, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.httpClient.get(`${path}`,
					{ headers: this.setHeaders(addHeaders, skipAuthorisation, userId), params: params }).subscribe(resp => {
						try {
							let pResp = this.processActionResult(resp);
							if (pResp["IsSuccess"]) {
								observer.next(pResp);
							} else {
								observer.error(pResp);
							}
						} catch (actionError) {
							observer.error(actionError);
						}
					}, (error: HttpErrorResponse) => {
						this.processRequestError(error);
						observer.error(error);
					});
			} catch (ex) {
				observer.error(ex);
			}
		});
	}
	delete(path: string, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				this.actualDelete(`${this.appInfo.ApiUrl}${path}`, params, addHeaders, skipAuthorisation, userId).subscribe(result => {
					observer.next(result);
				}, getError => {
					observer.error(getError);
				});
			} catch (ex) {
				console.log("HttpGet error", ex);
				observer.error(ex);
			}
		});
	}
	private actualDelete(path: string, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.httpClient.delete(`${path}`,
					{ headers: this.setHeaders(addHeaders, skipAuthorisation, userId), params: params }).subscribe(resp => {
						try {
							let pResp = this.processActionResult(resp);
							if (pResp["IsSuccess"]) {
								observer.next(pResp);
							} else {
								observer.error(pResp);
							}
						} catch (actionError) {
							observer.error(actionError);
						}
					}, (error: HttpErrorResponse) => {
						this.processRequestError(error);
						observer.error(error);
					});
			} catch (ex) {
				observer.error(ex);
			}
		});
	}
	post(path: string, body: any = {}, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = "", formData: boolean = false, dataAsString: boolean = true): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				this.actualPost(`${this.appInfo.ApiUrl}${path}`, body, params, addHeaders, skipAuthorisation, userId, formData, dataAsString).subscribe(result => {
					observer.next(result);
				}, postError => {
					observer.error(postError);
				});
			} catch (ex) {
				console.log("HttpPost error", ex);
				observer.error(ex);
			}
		});
	}
	private actualPost(path: string, body: any = {}, params: HttpParams = new HttpParams(), addHeaders: any = null
		, skipAuthorisation: boolean = false, userId: string = "", formData: boolean = false, dataAsString: boolean = true): Observable<any> {
		return new Observable(observer => {
			try {
				let clone = this.utilSvc.deepClone(body);
				this.utilSvc.fixDates(clone, "", false);
				if (dataAsString) {
					clone = this.utilSvc.stringifySubJsonFields(clone);
				} else {
					clone = body;
				}
				this.httpClient.post(`${path}`, clone, { headers: this.setHeaders(addHeaders, skipAuthorisation, userId, formData), params: params }).subscribe(resp => {
					try {
						let pResp = this.processActionResult(resp);
						if (pResp["IsSuccess"]) { observer.next(pResp); } else { observer.error(pResp); }
					} catch (actionError) { observer.error(actionError); }
				}, (error: HttpErrorResponse) => {
					this.processRequestError(error);
					observer.error(error);
				});
			} catch (ex) {
				observer.error(ex);
			}
		});
	}
	postAsFormData(path: string, body: FormData, params: HttpParams = new HttpParams(), addHeaders: any = null
		, skipAuthorisation: boolean = false, userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				let formData: boolean = true;
				this.httpClient.post(`${this.appInfo.ApiUrl}${path}`, body, { headers: this.setHeaders(addHeaders, skipAuthorisation, userId, formData, true), params: params }).subscribe(resp => {
					try {
						resp = this.utilSvc.parseSubJsonFields(resp);
						this.utilSvc.fixDates(resp, "", true);
						observer.next(resp);
					} catch (actionError) { observer.error(actionError); }
				}, (error: HttpErrorResponse) => {
					this.processRequestError(error);
					observer.error(error);
				});
			} catch (ex) {
				observer.error(ex);
			}
		});
	}
	getExternal(path: string, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				this.httpClient.get(`${path}`, { headers: this.setHeaders(addHeaders, skipAuthorisation, userId), params: params }).subscribe(resp => {
					try {
						observer.next(resp);
					} catch (actionError) { observer.error(actionError); }
				}, (error: HttpErrorResponse) => {
					this.processRequestError(error);
					observer.error(error);
				});
			} catch (ex) {
				console.log("HttpGetExternal error", ex);
				observer.error(ex);
			}
		});
	}
	postExternal(path: string, body: any = {}, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = "", formData: boolean = false, dataAsString: boolean = true): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				let clone = this.utilSvc.deepClone(body);
				this.utilSvc.fixDates(clone, "", false);
				if (dataAsString) { clone = this.utilSvc.stringifySubJsonFields(clone); } else { clone = body; }
				this.httpClient.post(`${path}`, clone, { headers: this.setHeaders(addHeaders, skipAuthorisation, userId, formData), params: params }).subscribe(resp => {
					try {
						observer.next(resp);
					} catch (actionError) { observer.error(actionError); }
				}, (error: HttpErrorResponse) => {
					this.processRequestError(error);
					observer.error(error);
				});
			} catch (ex) {
				console.log("HttpPostExternal error", ex);
				observer.error(ex);
			}
		});
	}
	getIpLocationDetail(path: string, params: HttpParams = new HttpParams(), userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				this.domSvc.LastMessageDate = new Date();
				this.actualGet(`${this.appInfo.ApiUrl}${path}`, params, null, false, userId).subscribe(resp => {
					observer.next(resp);
				}, error => {
					observer.error(error);
				});
			} catch (ex) {
				console.log("getIpLocationDetail error", ex);
				observer.error(ex);
			}
		});
	}
	checkAuthenticated(componentName: string = ""): Observable<any> {
		return new Observable(observer => {
			this.domSvc.LastMessageDate = new Date();
			let httpParams: HttpParams = new HttpParams();
			let appInfo = this.domSvc.AppInfo;
			this.getAuthorizeService(appInfo.CheckAuthenticationAction, httpParams).subscribe((resp: ActionResult) => {
				if (resp && resp.IsSuccess && resp.Data) {
					let authenticatedUser = resp.Data as UserDto;
					this.domSvc.UserInfo = authenticatedUser;
					observer.next(resp.IsSuccess);
				} else {
					this.refreshToken(this.domSvc.RefreshToken).subscribe((refreshTokenResponse: ActionResult) => {
						if (refreshTokenResponse && refreshTokenResponse.IsSuccess && refreshTokenResponse.Data) {
							let authUser = refreshTokenResponse.Data as UserDto;
							observer.next(refreshTokenResponse.IsSuccess);
						} else {
							observer.next(null);
						}
					}, (error1: any) => { observer.next(null); });
				}
			}, (error: any) => {
				observer.next(null);
			});
		});
	}
	// Start of User Access and authorisation stuff
	getAuthorizeService(path: string = "", params: HttpParams = new HttpParams(), userId: string = ""): Observable<any> {
		return new Observable(observer => {
			try {
				let appInfo = this.domSvc.AppInfo;
				this.actualGet(`${appInfo.ApiUrl}${path}`, params, null, false, userId).subscribe(resp => {
					observer.next(resp);
				}, error => {
					observer.error(error);
				});
			} catch (ex) { }
		});
	}
	postAuthorizeService(path: string = "", body: any = {}, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false, userId: string = "", formData: boolean = false, dataAsString: boolean = true): Observable<any> {
		return new Observable(observer => {
			try {
				let appInfo = this.domSvc.AppInfo;
				this.actualPost(`${appInfo.ApiUrl}${path}`, body, params, addHeaders, skipAuthorisation, userId, formData, dataAsString).subscribe(resp => {
					observer.next(resp);
				}, error => {
					observer.error(error);
				});
			} catch (ex) { }
		});
	}
	// End of User Access and authorisation stuff

	tokenPost(path: string = "", body: any = {}, params: HttpParams = new HttpParams(), addHeaders: any = null, skipAuthorisation: boolean = false): Observable<any> {
		return new Observable(observer => {
			let appInfo = this.domSvc.AppInfo;
			this.actualPost(`${appInfo.ApiUrl}${path}`, body, params, addHeaders, skipAuthorisation, "", false, true).subscribe((tokenResp: ActionResult) => {
				try {
					if (tokenResp && tokenResp.IsSuccess) {
						if (tokenResp.access_token) {
							this.domSvc.Authorisation = tokenResp.access_token;
						} else {
							delete localStorage["Authorisation"];
						}
						if (tokenResp.expires) {
							this.domSvc.Expires = new Date(tokenResp.expires);
						} else {
							delete localStorage["Expires"];
						}
						if (tokenResp.refresh_token) {
							this.domSvc.RefreshToken = tokenResp.refresh_token;
						} else {
							delete localStorage["RefreshToken"];
						}
						if (tokenResp.Data) {
							let authenticatedUser = this.utilSvc.parseSubJsonFields(tokenResp.Data) as UserDto;
							this.domSvc.UserInfo = authenticatedUser;
							observer.next(authenticatedUser);
						} else {
							delete localStorage["UserInfo"]
							observer.next(null);
						}
					} else {
						this.domSvc.credentialCleanUp();
						observer.next(null);
					}
				} catch (err) {
					observer.error(err);
				}
			}, err => {
				observer.error(err);
			});
		});
	}
	signin(user: UserDto): Observable<any> {
		return new Observable(observer => {
			let appInfo = this.domSvc.AppInfo;
			let httpParams: HttpParams = new HttpParams();
			this.tokenPost(appInfo.LoginAction, user, httpParams, null, false).subscribe((response: any) => {
				observer.next(response);
			}, err => {
				observer.next(null);
			});
		});
	}
	refreshToken(token: string): Observable<any> {
		return new Observable(observer => {
			let appInfo = this.domSvc.AppInfo;
			if (token) {
				let data: any = encodeURIComponent(token);
				let httpParams: HttpParams = new HttpParams();
				this.tokenPost(appInfo.RefreshTokenAction, data, httpParams, null, false).subscribe((response: any) => {
					observer.next(response);
				}, err => {
					observer.next(null);
				});
			} else { observer.next(null); }
		});
	}
	signOut(): Observable<any> {
		let httpParams: HttpParams = new HttpParams();
		let appInfo = this.domSvc.AppInfo;
		return this.postAuthorizeService(appInfo.LogOutAction, null, httpParams, null, false);
	}
	redeemAuthorization(id: string, code: string): Observable<any> {
		return new Observable(observer => {
			let appInfo = this.domSvc.AppInfo;
			if (id) {
				let data: any = { "Username": encodeURIComponent(id), "Password": encodeURIComponent(code) };
				let httpParams: HttpParams = new HttpParams();
				this.tokenPost("/Authentication/RedeemAuthorization", data, httpParams, null, false).subscribe((response: any) => {
					observer.next(response);
				}, err => {
					observer.next(null);
				});
			} else { observer.next(null); }
		});
  }
  download(
    path: string,
    params: HttpParams = new HttpParams(),
    addHeaders: any = null,
    skipAuthorisation: boolean = false,
    userId: string = ""
  ): Observable<Blob> {
    return new Observable((observer) => {
      try {
        this.actualDownload(
          `${this.appInfo.ApiUrl}${path}`,
          params,
          addHeaders,
          skipAuthorisation,
          userId
        ).subscribe(
          (result) => {
            observer.next(result);
          },
          (getError) => {
            observer.error(getError);
          }
        );
      } catch (ex) {
        console.error("HttpGetForDownload error", ex);
        observer.error(ex);
      }
    });
  }
  private actualDownload(
    path: string,
    params: HttpParams = new HttpParams(),
    addHeaders: any = null,
    skipAuthorisation: boolean = false,
    userId: string = ""
  ): Observable<Blob> {
    return new Observable((observer) => {
      try {
        this.httpClient
          .get(path, {
            headers: this.setHeaders(addHeaders, skipAuthorisation, userId),
            params: params,
            responseType: 'blob',
          })
          .subscribe(
            (resp) => {
              observer.next(resp);
            },
            (error: HttpErrorResponse) => {
              this.processRequestError(error);
              observer.error(error);
            }
          );
      } catch (ex) {
        observer.error(ex);
      }
    });
  }
}
