import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Observable, of, map, switchMap, combineLatest } from "rxjs";
import { ApiService } from "../base/api.service";
import { ActionResult, DataResult, LazyLoadArgs } from "../../model/shared";
import { AppContextService } from "../base/app-context.service";
import { Constants, SystemResourceDto, DomainTypeDto, DomainValueDto, PageContentDto, WARLProgramDto, WARLSubmissionDto, ANZPACBaselineDto, SFLoginLogDto, WARLUpdateLogDto, WARLResourceDto, UserDto, UserRoleDto, OrganisationContactDto } from "../../model/feature/apcomodel";
import { OrganisationDto } from "../../model/feature/apcomodel/OrganisationDto";

@Injectable({
    providedIn: "root"
})
export class ConfigService {
    private apiSvc: ApiService;
    constructor(private appCtx: AppContextService) {
        this.apiSvc = this.appCtx.apiSvc;
    }
    getDomainTypePagedList(searchData: LazyLoadArgs): Observable<DataResult<DomainTypeDto>> {
        return this.apiSvc.post("/configuration/getDomainTypes", searchData) as Observable<DataResult<DomainTypeDto>>;
    }
    getDomainTypeById(id: string): Observable<DataResult<DomainTypeDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("domainTypeId", id);
        return this.apiSvc.get("/configuration/GetDomainType", httpParams);
    }
    saveDomainType(item: DomainTypeDto): Observable<ActionResult> {
        let postItem: DomainTypeDto = item;
        return this.apiSvc.post("/configuration/saveDomainType", postItem);
    }
    removeDomainType(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("domainTypeId", id);
        return this.apiSvc.delete("/configuration/removeDomainType", httpParams);
    }
    getInitDomainType(): Observable<{ dataTypes: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.DataType)
        ]).pipe(map(resp => {
            return {
                dataTypes: resp[0]
            };
        }));
    }
    getDomainValuePagedList(searchData: LazyLoadArgs): Observable<DataResult<DomainValueDto>> {
        return this.apiSvc.post("/configuration/getDomainValues", searchData) as Observable<DataResult<DomainValueDto>>;
    }
    saveDomainValue(item: DomainValueDto): Observable<ActionResult> {
        let postItem: DomainValueDto = item;
        return this.apiSvc.post("/configuration/saveDomainValue", postItem);
    }
    removeDomainValue(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("domainValueId", id);
        return this.apiSvc.delete("/configuration/removeDomainValue", httpParams);
    }
    getDomainValueByTypeAndCode(domainTypeName: string, codeValue: string): Observable<DataResult<DomainValueDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("domainTypeName", domainTypeName)
            .append("codeValue", codeValue);
        return this.apiSvc.get("/shared/getDomainValueByTypeAndCode", httpParams);
    }
    getDomainValuesByTypeName(domainTypeName: string): Observable<DataResult<DomainValueDto>> {
        let httpParams = new HttpParams().append("domainTypeName", domainTypeName);
        return this.apiSvc.get("/shared/getDomainValuesByTypeName", httpParams);
    }

    getSystemResourcePagedList(searchData: LazyLoadArgs): Observable<DataResult<SystemResourceDto>> {
        return this.apiSvc.post('/configuration/GetSystemResources', searchData) as Observable<DataResult<SystemResourceDto>>;
    }
    getSystemResourceById(id: string): Observable<DataResult<SystemResourceDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("systemResourceId", id);
        return this.apiSvc.get("/configuration/GetSystemResource", httpParams);
    }
    saveSystemResource(item: SystemResourceDto): Observable<ActionResult> {
        let postItem: SystemResourceDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/saveSystemResource", postItem, httpParams);
    }
    removeSystemResource(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("systemResourceId", id);
        return this.apiSvc.delete("/configuration/removeSystemResource", httpParams);
    }
    getInitSystemResourceDomain(): Observable<{ systemResourceTypes: DataResult<DomainValueDto>, dbTypes: DataResult<DomainValueDto>, storageTypes: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.SystemResourceType),
            this.getDomainValuesByTypeName(Constants.DomainType.SystemDatabaseType),
            this.getDomainValuesByTypeName(Constants.DomainType.SystemStorageType)
        ]).pipe(map(resp => {
            return {
                systemResourceTypes: resp[0],
                dbTypes: resp[1],
                storageTypes: resp[2]
            };
        }));
    }
    getSystemResourceList(): Observable<DataResult<SystemResourceDto>> {
        return this.apiSvc.get("/configuration/getSystemResourceList");
    }

    getPageContentPagedList(searchData: LazyLoadArgs): Observable<DataResult<PageContentDto>> {
        return this.appCtx.apiSvc.post('/configuration/GetPageContents', searchData) as Observable<DataResult<PageContentDto>>;
    }
    getInitPageContentDomain(): Observable<{ contentTypes: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.CMSPageContentType)
        ]).pipe(map(resp => {
            return {
                contentTypes: resp[0]
            };
        }));
    }
    getPageContentById(id: string): Observable<DataResult<PageContentDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("pageContentId", id);
        return this.apiSvc.get("/configuration/GetPageContent", httpParams);
    }
    savePageContent(item: PageContentDto): Observable<ActionResult> {
        let pageContentDto: PageContentDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/SavePageContent", pageContentDto, httpParams);
    }
    removePageContent(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("pageContentId", id);
        return this.apiSvc.delete("/configuration/RemovePageContent", httpParams);
    }

    getWARLProgramPagedList(searchData: LazyLoadArgs): Observable<DataResult<WARLProgramDto>> {
        return this.apiSvc.post('/configuration/getWARLPrograms', searchData) as Observable<DataResult<WARLProgramDto>>;
    }
    getWARLProgramById(id: string): Observable<DataResult<WARLProgramDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLProgramId", id);
        return this.apiSvc.get("/configuration/getWARLProgram", httpParams);
    }
    saveWARLProgram(item: WARLProgramDto): Observable<ActionResult> {
        let postItem: WARLProgramDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/saveWARLProgram", postItem, httpParams);
    }
    removeWARLProgram(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLProgramId", id);
        return this.apiSvc.delete("/configuration/removeWARLProgram", httpParams);
    }
    getInitWARLProgramDomain(): Observable<{ programMaterialTypes: DataResult<DomainValueDto>, programRegions: DataResult<DomainValueDto>, programStatuses: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramMaterialType),
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramRegion),
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramStatus)
        ]).pipe(map(resp => {
            return {
                programMaterialTypes: resp[0],
                programRegions: resp[1],
                programStatuses: resp[2]
            };
        }));
    }

    getWARLSubmissionPagedList(searchData: LazyLoadArgs): Observable<DataResult<WARLSubmissionDto>> {
        return this.apiSvc.post('/configuration/getWARLSubmissions', searchData) as Observable<DataResult<WARLSubmissionDto>>;
    }
    getWARLSubmissionById(id: string): Observable<DataResult<WARLSubmissionDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLSubmissionId", id);
        return this.apiSvc.get("/configuration/getWARLSubmission", httpParams);
    }
    saveWARLSubmission(item: WARLSubmissionDto): Observable<ActionResult> {
        let postItem: WARLSubmissionDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/saveWARLSubmission", postItem, httpParams);
    }
    removeWARLSubmission(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLSubmissionId", id);
        return this.apiSvc.delete("/configuration/removeWARLSubmission", httpParams);
    }
    getInitWARLSubmissionDomain(): Observable<{ submissionTypes: DataResult<DomainValueDto>, submissionMaterials: DataResult<DomainValueDto>, submissionRegions: DataResult<DomainValueDto>, submissionStatuses: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.WSubmissionType),
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramMaterialType),
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramRegion),
            this.getDomainValuesByTypeName(Constants.DomainType.WSubmissionStatus)
        ]).pipe(map(resp => {
            return {
                submissionTypes: resp[0],
                submissionMaterials: resp[1],
                submissionRegions: resp[2],
                submissionStatuses: resp[3]
            };
        }));
    }

    getWARLUpdateLogPagedList(searchData: LazyLoadArgs): Observable<DataResult<WARLUpdateLogDto>> {
        return this.apiSvc.post('/configuration/getWARLUpdateLogs', searchData) as Observable<DataResult<WARLUpdateLogDto>>;
    }
    getWARLUpdateLogById(id: string): Observable<DataResult<WARLUpdateLogDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLUpdateLogId", id);
        return this.apiSvc.get("/configuration/getWARLUpdateLog", httpParams);
    }
    saveWARLUpdateLog(item: WARLUpdateLogDto): Observable<ActionResult> {
        let postItem: WARLUpdateLogDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/saveWARLUpdateLog", postItem, httpParams);
    }
    removeWARLUpdateLog(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLUpdateLogId", id);
        return this.apiSvc.delete("/configuration/removeWARLUpdateLog", httpParams);
    }
    getInitWARLUpdateLogDomain(): Observable<{ programMaterialTypes: DataResult<DomainValueDto>, programRegions: DataResult<DomainValueDto>, programStatuses: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramMaterialType),
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramRegion),
            this.getDomainValuesByTypeName(Constants.DomainType.WProgramStatus)
        ]).pipe(map(resp => {
            return {
                programMaterialTypes: resp[0],
                programRegions: resp[1],
                programStatuses: resp[2]
            };
        }));
    }
    getWARLResourcePagedList(searchData: LazyLoadArgs): Observable<DataResult<WARLResourceDto>> {
        return this.apiSvc.post('/configuration/getWARLResources', searchData) as Observable<DataResult<WARLResourceDto>>;
    }
    getWARLResourceById(id: string): Observable<DataResult<WARLResourceDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLResourceId", id);
        return this.apiSvc.get("/configuration/getWARLResource", httpParams);
    }
    saveWARLResource(item: WARLResourceDto): Observable<ActionResult> {
        let postItem: WARLResourceDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/saveWARLResource", postItem, httpParams);
    }
    removeWARLResource(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("webARLResourceId", id);
        return this.apiSvc.delete("/configuration/removeWARLResource", httpParams);
    }

    getUserPagedList(searchData: LazyLoadArgs): Observable<DataResult<UserDto>> {
        return this.apiSvc.post('/user/getUsers', searchData) as Observable<DataResult<UserDto>>;
    }
    getUserById(id: string): Observable<DataResult<UserDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("userId", id);
        return this.apiSvc.get("/user/getUser", httpParams);
    }
    getUserEnvironmentCode(item: UserDto): Observable<DataResult<UserDto>> {
        let postItem: UserDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.get("/authentication/getEnvironmentCode", httpParams);
    }
    setUserEnvironmentCode(environmentCode: string): Observable<ActionResult> {
        let postItem: string = environmentCode;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/authentication/setEnvironmentCode", postItem, httpParams);
    }
    saveUser(item: UserDto): Observable<ActionResult> {
        let postItem: UserDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/user/saveUser", postItem, httpParams);
    }
    requestUserConfirmation(id: string): Observable<ActionResult> {
        let postItem: string = id;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/user/requestUserConfirmation", postItem, httpParams);
    }
    requestUserResetPassword(id: string): Observable<ActionResult> {
        let postItem: string = id;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/user/requestUserResetPassword", postItem, httpParams);
    }
    removeUser(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("userId", id);
        return this.apiSvc.delete("/user/removeUser", httpParams);
    }
    getInitUserDomain(): Observable<{ systemEnvironments: DataResult<DomainValueDto>, roleTypeCodes: DataResult<DomainValueDto>, roleCodes: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.SystemEnvironment),
            this.getDomainValuesByTypeName(Constants.DomainType.UserRoleType),
            this.getDomainValuesByTypeName(Constants.DomainType.UserRole)
        ]).pipe(map(resp => {
            return {
                systemEnvironments: resp[0],
                roleTypeCodes: resp[1],
                roleCodes: resp[2]
            };
        }));
    }
    getUserRolePagedList(searchData: LazyLoadArgs): Observable<DataResult<UserRoleDto>> {
        return this.apiSvc.post("/user/getUserRoles", searchData) as Observable<DataResult<UserRoleDto>>;
    }

    getOrganisationPagedList(searchData: LazyLoadArgs): Observable<DataResult<OrganisationDto>> {
        return this.apiSvc.post('/configuration/getOrganisations', searchData) as Observable<DataResult<OrganisationDto>>;
    }
    getOrganisationById(id: string): Observable<DataResult<OrganisationDto>> {
        let httpParams: HttpParams = new HttpParams()
            .append("organisationId", id);
        return this.apiSvc.get("/configuration/getOrganisation", httpParams);
    }
    saveOrganisation(item: OrganisationDto): Observable<ActionResult> {
        let postItem: OrganisationDto = item;
        let httpParams: HttpParams = new HttpParams();
        return this.apiSvc.post("/configuration/saveOrganisation", postItem, httpParams);
    }
    removeOrganisation(id: string): Observable<ActionResult> {
        let httpParams: HttpParams = new HttpParams()
            .append("organisationId", id);
        return this.apiSvc.delete("/configuration/removeOrganisation", httpParams);
    }
    getInitOrganisationDomain(): Observable<{ prepStatusCodes: DataResult<DomainValueDto>, dbActionStatuses: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.getDomainValuesByTypeName(Constants.DomainType.PREPStatusCode),
            this.getDomainValuesByTypeName(Constants.DomainType.DatabaseActionStatus)
        ]).pipe(map(resp => {
            return {
                prepStatusCodes: resp[0],
                dbActionStatuses: resp[1]
            };
        }));
    }
    getOrganisationContactsPagedList(searchData: LazyLoadArgs): Observable<DataResult<OrganisationContactDto>> {
        return this.apiSvc.post("/configuration/getOrganisationContacts", searchData) as Observable<DataResult<OrganisationContactDto>>;
    }
    getAuthAutoCompleteDomainValueList(searchData: LazyLoadArgs): Observable<DataResult<DomainValueDto>> {
        return this.apiSvc.post('/shared/getAuthAutoCompleteDomainValueList', searchData) as Observable<DataResult<DomainValueDto>>;
    }
    getLastSFLogins(searchData: LazyLoadArgs): Observable<DataResult<SFLoginLogDto>> {
        return this.apiSvc.post('/configuration/getLastSFLogins', searchData) as Observable<DataResult<SFLoginLogDto>>;
    }
    getSFLogins(searchData: LazyLoadArgs): Observable<DataResult<SFLoginLogDto>> {
        return this.apiSvc.post('/configuration/getSFLogins', searchData) as Observable<DataResult<SFLoginLogDto>>;
    }
    getSFLoginStats(searchData: LazyLoadArgs): Observable<DataResult<SFLoginLogDto>> {
        return this.apiSvc.post('/configuration/getSFLoginStats', searchData) as Observable<DataResult<SFLoginLogDto>>;
    }
}
