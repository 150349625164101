import { BaseDto } from "../baseDto"
/* SystemResourceBaseDto generated at 2024-12-06 13:53:49.774 +11:00*/
export class SystemResourceBaseDto extends BaseDto {
	SystemResourceId?: string;
	ResourceCode?: string;
	ResourceName?: string;
	ResourceDescription?: string;
	SystemResourceType?: string;
	EnvironmentCode?: string;
	SequenceNo?: number;
	ServerName?: string;
	DBType?: string;
	DBProvider?: string;
	DBInstanceName?: string;
	DBName?: string;
	StorageType?: string;
	AppDbVersion?: number;
	CurrentAppDbVersion?: number;
	OrgDbVersion?: number;
	OrgDbEdition?: string;
	OrgDbElasticPool?: string;
	OrgDbDefaultGbSize?: string;
	AccountNo?: string;
	Username?: string;
	Password?: string;
	LinkedServerName?: string;
	ParamDomainTypeName?: string;
	ParamValueJSON?: string;
	RootPath?: string;
	LogRootPath?: string;
	BackupRootPath?: string;
	CloudRootFolderName?: string;
	AvailableBytes?: number;
	UsedBytes?: number;
	SubscriberCapacity?: number;
	AllocatedCapacity?: number;
	CreateOrgDbScript?: string;
	StatusCode?: string;
}
