import { BaseDto } from "../baseDto"
/* ProductAttributeBaseDto generated at 2024-12-06 13:53:49.578 +11:00*/
export class ProductAttributeBaseDto extends BaseDto {
	ProductAttributeId?: string;
	ProductId?: string;
	ProductComponentId?: string;
	ProductComponentGroupId?: string;
	ProductSKUId?: string;
	ObjectAttributeVersionId?: string;
	AttributeJSON?: string;
	StatusCode?: string;
}
