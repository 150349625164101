import { BaseDto } from "../baseDto"
/* ARLAssessmentReportBaseDto generated at 2024-12-06 13:53:49.386 +11:00*/
export class ARLAssessmentReportBaseDto extends BaseDto {
	ARLAssessmentReportId?: string;
	ProjectId?: string;
	ProjectVersionNo?: number;
	AssessmentReportId?: number;
	AssessmentReportDate?: Date;
	AssessmentLocationCode?: string;
	PREP_LocationId?: number;
	ReportTypeCode?: string;
	AssessmentReport?: string;
	StatusCode?: string;
}
