import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { Globals } from './globals';
import { AuthModule } from './features/auth-module/auth.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppContextService } from './core/service';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    AuthModule,
    CarouselModule 
  ],
  providers: [
    Globals,
    MessageService,
    CookieService,
    ConfirmationService,
    AppContextService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
