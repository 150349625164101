import { BaseDto } from "../baseDto"
/* OrganisationContactBaseDto generated at 2024-12-06 13:53:49.549 +11:00*/
export class OrganisationContactBaseDto extends BaseDto {
	OrganisationContactId?: string;
	SF_ACRId?: string;
	OrganisationId?: string;
	ContactId?: string;
	UserId?: string;
	DefaultEnvironmentCode?: string;
	SF_AccountId?: string;
	SF_ContactId?: string;
	ARLM_OrganisationId?: string;
	ARLM_UserId?: string;
	PREP_OrganisationId?: number;
	PREP_UserId?: number;
	PREP_StatusCode?: string;
	Basecamp_UserId?: number;
	StatusCode?: string;
}
