import { BaseDto } from "../baseDto"
/* InboundDataBaseDto generated at 2024-12-06 13:53:49.493 +11:00*/
export class InboundDataBaseDto extends BaseDto {
	InboundDataId?: string;
	ARLFileId?: string;
	DataClassificationCode?: string;
	ReferenceId1?: string;
	ReferenceId2?: string;
	ReferenceId3?: string;
	SequenceNo?: number;
	FileData?: string;
	ValidationErrors?: string;
	ErrorMessage?: string;
	DateProcessed?: Date;
	StatusCode?: string;
}
