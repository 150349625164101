import { BaseDto } from "../baseDto"
/* ANZPACBaselineBaseDto generated at 2024-12-06 13:53:49.358 +11:00*/
export class ANZPACBaselineBaseDto extends BaseDto {
	ANZPACBaselineId?: string;
	BaselineType?: string;
	ReportingYear?: number;
	ANZPACReportId?: string;
	Sequence?: number;
	ItemName?: string;
	Item_Description?: string;
	IsApplicable?: boolean;
	SetupEMF?: string;
	SetupAU?: string;
	SetupNZ?: string;
	SetupPI?: string;
	SetupSystem?: string;
	TotalVolume_Plastic?: number;
	AccuracyLvl_Vol_Plastic?: string;
	TotalRecycledContent?: number;
	AccuracyLvl_Content?: string;
	TotalSortedContent?: number;
	NoOfUnits?: number;
	Evidence_Description?: string;
	PercentSold?: number;
	TotalPackagingPlastic?: number;
	PercentageRecyclable?: number;
	IsPackagingVerified?: boolean;
	Comments?: string;
	RA_IsRecyclingExists?: boolean;
	StatusCode?: string;
}
