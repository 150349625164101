import { BaseDto } from "../baseDto"
/* ScheduledTaskParameterBaseDto generated at 2024-12-06 13:53:49.656 +11:00*/
export class ScheduledTaskParameterBaseDto extends BaseDto {
	ScheduleParameterValueId?: string;
	ScheduledTaskId?: string;
	IsNullValue?: boolean;
	SequenceNumber?: number;
	ParamName?: string;
	ParamDataTypeCode?: string;
	ParamTextValue?: string;
	ParamNumberValue?: number;
	ParamDateValue?: Date;
	ParamBooleanValue?: boolean;
	ParamJSON?: string;
}
