import { BaseDto } from "../baseDto"
/* ProductPackagingBaseDto generated at 2024-12-06 13:53:49.595 +11:00*/
export class ProductPackagingBaseDto extends BaseDto {
	ProductPackagingId?: string;
	ProductId?: string;
	ProductVersionNo?: number;
	ProjectId?: string;
	ProjectVersionNo?: number;
	SequenceNo?: number;
	PackageTypeCode?: string;
	PackageName?: string;
	Shape_Style?: string;
	PackageWeight?: number;
	PackageWeightUOM?: string;
	ProductQuantity?: number;
	Org_ProductPackagingId?: string;
	Org_ProductId?: string;
	Org_ProjectId?: string;
	Org_StatusCode?: string;
	PREP_ProjectId?: number;
	StatusCode?: string;
}
