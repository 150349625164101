import { BaseDto } from "../baseDto"
/* SequenceNoBaseDto generated at 2024-12-06 13:53:49.670 +11:00*/
export class SequenceNoBaseDto extends BaseDto {
	SequenceNoId?: string;
	SequenceType?: string;
	ReferenceName?: string;
	LastSequenceNo?: number;
	Value?: string;
	StatusCode?: string;
}
