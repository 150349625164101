import { BaseDto } from "../baseDto"
/* UserRoleBaseDto generated at 2024-12-06 13:53:49.777 +11:00*/
export class UserRoleBaseDto extends BaseDto {
	UserRoleId?: string;
	UserId?: string;
	OrganisationId?: string;
	RoleTypeCode?: string;
	RoleCode?: string;
	StatusCode?: string;
}
