import { BaseDto } from "../baseDto"
/* ProjectItemBaseDto generated at 2024-12-06 13:53:49.631 +11:00*/
export class ProjectItemBaseDto extends BaseDto {
	ProjectItemId?: string;
	ProjectId?: string;
	ProjectVersionNo?: number;
	ParentItemId?: string;
	Supplier_ProjectItemId?: string;
	ProjectItemGroupId?: string;
	ItemType?: string;
	SequenceNo?: number;
	ItemName?: string;
	ItemDescription?: string;
	PartNo?: string;
	MaterialId?: number;
	MaterialType?: string;
	MaterialName?: string;
	MaterialComponentCode?: string;
	IsHazardousContent?: boolean;
	IsHazardousRinse?: boolean;
	ItemLength?: number;
	ItemLengthUOM?: string;
	ItemWidth?: number;
	ItemWidthUOM?: string;
	ItemDepth?: number;
	ItemDepthUOM?: string;
	ItemWeight?: number;
	ItemWeightUOM?: string;
	ItemCapacity?: number;
	ItemCapacityUOM?: string;
	ItemSurfaceArea?: number;
	ItemSurfaceAreaUOM?: string;
	CrushedLength?: number;
	CrushedLengthUOM?: string;
	CrushedWidth?: number;
	CrushedWidthUOM?: string;
	CrushedDepth?: number;
	CrushedDepthUOM?: string;
	InkType?: string;
	Rigidity?: string;
	GaugeUOM?: string;
	SoftPlasticType?: string;
	SoftPlasticMaterial?: string;
	GaugeType?: string;
	GaugeThickness?: number;
	GaugeWeight?: number;
	IsDarkShade?: boolean;
	IsCarbonBlack?: boolean;
	CarbonBlack?: string;
	IsTinted?: boolean;
	GlassDarkTint?: string;
	Colour?: string;
	GlassColour?: string;
	Opacity?: string;
	Bonding?: string;
	MaterialPercentage?: number;
	SurfacePercentage?: number;
	ItemQuantity?: number;
	IsCombinedItem?: boolean;
	IsProductResidue?: boolean;
	ProductResidueType?: string;
	IsPlasticAdditive?: boolean;
	PlasticAdditiveType?: string;
	IsPlasticLowDensity?: boolean;
	ProductId?: string;
	ProductComponentGroupId?: string;
	ProductComponentId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	Org_ProjectItemId?: string;
	Org_ParentItemId?: string;
	ARLM_ProjectItemId?: string;
	ARLM_ParentItemId?: string;
	ARLM_SupplierProjectItemId?: string;
	PREP_ProjectId?: number;
	PREP_ItemId?: number;
	PREP_ParentItemId?: number;
	PREP_ReferenceId?: string;
	RelatedProjectId?: string;
	RelatedItemId?: string;
	StatusCode?: string;
}
