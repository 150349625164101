<div class="flex-wrap" *ngIf="showComponent && visible">
    <div class="flex-wrap">
        <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
            <b>Set Password</b>
        </div>
    </div>
    <div class="flex-wrap mt-2">
        <form name="form" class="flex-wrap" (ngSubmit)="onRequest()" #thisForm="ngForm" autocomplete="off" novalidate>
            <div class="flex-wrap p2" *ngIf="showResetPassword">
                <div class="flex-wrap">
                    <span>Please provide your email and the new password</span>
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* Email</label>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="username" type="text" [(ngModel)]="user.Username" required pInputText validateEmail [disabled]="isUsernameDisabled()" />
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* Password</label>
                    <i class="fas fa-eye" (click)="showPassword = !showPassword"></i>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="newPassword" type="{{(showPassword) ? 'text' : 'password'}}"
                        [(ngModel)]="user.Password" required pInputText validatePassword />
                </div>
                <div class="flex-wrap mt-2">
                    <span>
                        The password length must be minimum of 8 characters including caps and symbols.
                    </span>
                </div>
                <div class="flex-wrap mt-2">
                    <span style="color:red;">* denotes required details.</span>
                </div>
            </div>
            <div class="flex-wrap p2" *ngIf="showEnterVerificationCode">                
                <div class="flex-wrap">
                    <span>You will receive a verification code through email. Please enter the verification code below to proceed with updating your password.</span>
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* Verification Code</label>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="username" type="text" [(ngModel)]="user.VerificationCode" pInputText />
                </div>
            </div>
            <div class="flex-wrap p2" *ngIf="showSuccess">
                <div class="flex-wrap">                    
                    <span class="center">Your password update request is being processed. You will receive an email confirming the update.</span>
                </div>
            </div>
            <div class="flex-wrap p2" *ngIf="showError">
                <div class="flex-wrap">                    
                    <span class="center">An error occured while updating your password. Please try again later.</span>
                </div>
            </div>
            <div class="flex-wrap">
                <div class="flex-wrap mt-2 p2" *ngIf="showResetPassword">
                    <p-button type="submit" class="flex-wrap button button-highlight button-block center" [disabled]="!isValid"
                        (click)="onRequestForVerificationCode()">
                        <span class="center">Set Password</span>
                    </p-button>
                </div>
                <div class="flex-wrap mt-2 p2" *ngIf="showEnterVerificationCode">
                    <p-button type="button" class="flex-wrap button button-highlight button-block center" 
                        (click)="onRequest()">
                        <span class="center">Verify</span>
                    </p-button>
                </div>
            </div>
        </form>
    </div>
</div>