import { BaseDto } from "../baseDto"
/* ContactBaseDto generated at 2024-12-06 13:53:49.442 +11:00*/
export class ContactBaseDto extends BaseDto {
	ContactId?: string;
	UserId?: string;
	SF_ContactId?: string;
	ARLM_UserId?: string;
	FirstName?: string;
	LastName?: string;
	Email?: string;
	CompanyPosition?: string;
	StatusCode?: string;
}
