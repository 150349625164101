import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { FileDto, Guid } from "../../model/shared";
import { Observable } from "rxjs";
@Injectable({
    providedIn: "root"
})
export class UtilityService {
    constructor() { }
    newGuid() {
        return Guid.newGuid();
    }
    getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }
    //#region Date and MomentJs stuff
    IsDate(iptDatetime: string = "") {
        let lvReturn: boolean = false;
        try {
            if (iptDatetime) {
                let testDate = new Date(iptDatetime);
                lvReturn = true;
            }
        } catch (e) {
            console.error(e);
        }
        return lvReturn;
    }
    dateSecondsDiff(startDate: any, endDate: any) {
        var timeDiff = Math.abs(endDate) - Math.abs(startDate);
        var diffDays = Math.ceil(timeDiff / 1000);
        return diffDays;
    };
    getDate(iptDatetime: string = "") {
        try {
            if (typeof (moment) !== "undefined") {
                if (typeof iptDatetime !== "undefined" && iptDatetime)
                    return moment(new Date(iptDatetime));
                else
                    return moment();
            } else {
                if (typeof iptDatetime !== "undefined" && iptDatetime)
                    return new Date(iptDatetime);
                else
                    return new Date();
            }
        } catch (e) {
            console.error(e);
            return new Date();
        }
    }
    //TimeZone handling	
    fixDates(obj: any, defaultTimeZoneCode: string = "", isIncoming: boolean = true): void {
        if (obj) {
            let timeZoneCode = obj["TimeZoneCode"];
            if (!timeZoneCode) {
                timeZoneCode = defaultTimeZoneCode;
            }
            for (let property in obj) {
                if (obj.hasOwnProperty(property)) {
                    if (timeZoneCode &&
                        (obj[property] instanceof Date
                            || (typeof obj[property] === "string" && property.indexOf("Date") > -1))) {
                        obj[property] = this.fixTimeZones(obj[property], timeZoneCode, isIncoming);
                    } else if (typeof obj[property] === "object") {
                        this.fixDates(obj[property], timeZoneCode, isIncoming);
                    }
                }
            }
        }
    }
    fixTimeZones(inDate: any | string, timeZoneCode: string, isIncoming: boolean = true): Date {
        let resultDate: Date = inDate;
        if (inDate) {
            if (!window["moment"](inDate).isValid()) {
                return resultDate;
            }
            let beforeDate = inDate;
            if (timeZoneCode) {
                let momentTimeZone = this.getMomentTimeZone(timeZoneCode);

                if (isIncoming) {
                    //parse the date written in timezone to browser.
                    resultDate = new Date(window["moment"].tz(beforeDate, momentTimeZone).format("YYYY-MM-DD HH:mm:ss.SSS"));
                } else {
                    //parse the date written in browser time to moment timezone.
                    resultDate = new Date(window["moment"].tz(window["moment"](beforeDate), momentTimeZone).format("YYYY-MM-DD HH:mm:ss.SSS"));
                }
            } else {
                resultDate = beforeDate;
            }
        }
        return resultDate;
    }
    getUiInputFormatsDate(inDate?: Date) {
        try {
            //"YYYY-MM-DD"
            if (inDate) {
                let ipt = inDate;
                //ipt = new Date(inDate);                
                let dd = ipt.getDate();
                let ddStr = "";
                if (dd < 10) {
                    ddStr = `0${dd}`;
                } else {
                    ddStr = `${dd}`;
                }
                let mm = ipt.getMonth() + 1;
                let mmStr = "";
                if (mm < 10) {
                    mmStr = `0${mm}`;
                } else {
                    mmStr = `${mm}`;
                }
                return `${ipt.getFullYear()}-${mmStr}-${ddStr}`;
            } else {
                return undefined;
            }
        } catch (ex) { return undefined; }
    }
    getUiInputFormatsDateMonthYear(inDate?: Date) {
        try {

            if (inDate) {
                let ipt = new Date(inDate);
                let mmStr = ipt.toLocaleString('default', { month: 'short' }).substring(0, 3).toLowerCase();
                let yyyy = ipt.getFullYear();
                return `${mmStr} ${yyyy}`;
            } else {
                return undefined;
            }
        } catch (ex) { return undefined; }
    }

    getMomentDate(inDate?: any) {
        if (inDate) {
            return window["moment"](new Date(inDate));
        } else {
            return window["moment"](new Date());
        }
    }
    getMomentTimeZone(timeZoneCode: string): string {
        if (timeZoneCode) {
            if (timeZoneCode === "AUS Eastern Standard Time" || timeZoneCode === "AEST") {
                return "Australia/Sydney";
            }
            if (timeZoneCode === "Cen. Australia Standard Time" || timeZoneCode === "ACST") {
                return "Australia/Adelaide";
            }
            if (timeZoneCode === "W. Australia Standard Time" || timeZoneCode === "AWST") {
                return "Australia/Perth";
            }
            if (timeZoneCode === "Aus Central W. Standard Time" || timeZoneCode === "ACWST") {
                return "Australia/Perth";
            }
            if (timeZoneCode === "AUS Central Standard Time" || timeZoneCode === "ACST") {
                return "Australia/Hobart";
            }
            if (timeZoneCode === "E. Australia Standard Time" || timeZoneCode === "AEST") {
                return "Australia/Brisbane";
            }
            if (timeZoneCode === "utc" || timeZoneCode === "UTC") {
                return "UTC";
            }
            return window["moment"].tz.guess();
        } else {
            return "UTC";
        }
    }
    //#endregion Date and MomentJs stuff

    //#region Object, Data and JSON handling for objects with property key ending with "JSON"
    deepClone<T>(sourceObj: T): T {
        return this.parseSubJsonFields(sourceObj) as T;
    }
    stringifyObject<T>(sourceObj: T): string {
        return this.stringifySubJsonFields(sourceObj);
    }
    parseObject(sourceObj: string): any {
        let retObj = this.deepClone(JSON.parse(sourceObj));
        this.fixDates(retObj, "", false);
        return retObj;
    }
    stringifySubJsonFields(obj: any): string {
        try {
            return JSON.stringify(obj,
                (key, value) => {
                    if (key.length >= 4 &&
                        key.slice(-4).toUpperCase() === "JSON" &&
                        value &&
                        typeof value === "object") {
                        return this.stringifySubJsonFields(value);
                    } else {
                        return value;
                    }
                });
        } catch (e) {
            console.error(e);
            return obj;
        }
    }
    parseSubJsonFields(obj: any) {
        try {
            //"Australia/Sydney"
            let timezone = "";
            for (let key in obj) {
                if (key.length >= 4 && (key.slice(-8).toLowerCase() === "timezone" || key.slice(0, 8).toLowerCase() === "timezone")) {
                    timezone = obj[key];
                }
            }
            return JSON.parse(JSON.stringify(obj),
                (key, value) => {
                    if (key.length >= 4 && (key.slice(-4).toUpperCase() === "DATE" || key.slice(0, 4).toUpperCase() === "DATE") && value && typeof value === "string") {
                        try {
                            if (value === "0001-01-01 00:00:00.000 +00:00" || value === "0001-01-01 00:00:00.000" || value === "0001-01-01" || value === "00:00:00.000" || value === "00:00:00") {
                                return null;
                            } else {
                                if (this.IsDate(value) && value.length > 7) {
                                    // if (timezone) {
                                    //     let nDate = (new Date(value)).toLocaleString("en-AU",{timeZone:timezone} );
                                    //     console.log(nDate);
                                    //     return nDate;
                                    //} else {
                                    return new Date(value);
                                    //}
                                } else {
                                    return value;
                                }
                                /*
                                return window["moment"](value).toDate(); 
                                */
                            }
                        } catch (e) {
                            console.error("Property is not a valid date", key, value);
                            return value;
                        }
                    } else {
                        if (key.length >= 2 && key.slice(-2).toUpperCase() === "ID" && value && typeof value === "string") {
                            try {
                                if (value === "00000000-0000-0000-0000-000000000000") {
                                    return undefined;
                                } else {
                                    return value;
                                }
                            } catch (e) {
                                console.error("Property is not a valid ID", key, value);
                                return value;
                            }
                        } else {
                            return value;
                        }
                    }
                });
        } catch (e) {
            console.error(e);
            return obj;
        }
    }
    //#endregion  Object, Data and JSON handling for objects with property key ending with "JSON"

    
	recursiveObservableProcess(theList: any, theFunction: any, refObject: any) {
		return new Observable(observer => {
			try {
				let results: any = [];
				//let itemList = JSON.parse(JSON.stringify(theList));
				let itemList: any = [];
				theList.forEach((val: any) => itemList.push(Object.assign({}, val)));
				let completedFunction = function () { if (itemList.length > 0) { manageList(); } else { observer.next(results); } };
				let manageList = function () {
					let theItem = null;
					if (itemList.length > 0) {
						theItem = itemList.shift();
						if (theItem !== null) {
							theFunction(theItem, refObject).subscribe((result: any) => {
								results.push(result);
								completedFunction();
							}, (funcErr: any) => { observer.error(funcErr); });
						} else { completedFunction(); }
					} else { completedFunction(); }
				};
				if (itemList.length > 0) { manageList(); } else { observer.next(results); }
			} catch (e) {
				observer.error(e);
			}
		});
	}
    downloadContent(content: Blob, fileName: string) {
		let url = window.URL.createObjectURL(content);
		this.donwloadFileUrl(url, fileName);
		window.URL.revokeObjectURL(url);
	}
    private donwloadFileUrl(url: string, fileName: any) {
		let a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
    
	readFileAsDataURL(files: File[], fileDto: FileDto): Observable<FileDto[]> {
		return new Observable(observer => {
			try {
				let index = 0;
				let list: FileDto[] = [];
				let readNext = () => {
					let file = files[index];
					let reader = new FileReader();
                    reader.onloadend = (evt) => {
                        index++;
                        let newFileDto: FileDto = JSON.parse(JSON.stringify(fileDto));
                        newFileDto.FileObject = file;
                        newFileDto.ObjectUri = evt.target?.result;
                        newFileDto.OriginalFilename = file.name;
                        newFileDto.FileSize = file.size;
                        newFileDto.FileTypeCode = file.name.split(".").pop();
                        newFileDto.MimeType = file.type;
                        list.push(newFileDto);
                        if (index < files.length) {
                            readNext();
                        } else {
                            observer.next(list);
                        }
                    };
                    reader.onerror = (e) => {
                        observer.error(e);
                    };
                    reader.readAsDataURL(file);
				};
				readNext();
			} catch (e) {
				observer.error(e);
			}
		});
	}
    convertBlobToBinary(data: any): Observable<any> {
		return new Observable(observer => {
			try {
				let reader = new FileReader();
				reader.onloadend = function () {
					let result = reader.result as any;
					let binaryData = new Uint8Array(result);
					observer.next(binaryData);
				}
				reader.onerror = function (e) {
					observer.error(e);
				}
				reader.readAsArrayBuffer(data);
			} catch (ex) {
				observer.error(ex);
				console.error(ex);
			}
		});
	}
}
