import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './service/auth-guard';
import { AuthenticationService } from './service/authentication.service';
import { AuthChangePasswordCmpnt, AuthChangePasswordDialog, AuthForgotPasswordCmpnt, AuthForgotPasswordDialog, AuthLocalSignInCmpnt, AuthResetPasswordCmpnt, AuthSignInCmpnt, AuthSignInDialog } from './component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule, CoreModule
	],
	declarations: [
		AuthSignInDialog, AuthSignInCmpnt, AuthLocalSignInCmpnt,
		AuthChangePasswordCmpnt, AuthChangePasswordDialog,
		AuthForgotPasswordCmpnt, AuthForgotPasswordDialog,
		AuthResetPasswordCmpnt
	],
	exports: [
		AuthSignInDialog, AuthSignInCmpnt, AuthLocalSignInCmpnt,
		AuthChangePasswordCmpnt, AuthChangePasswordDialog,
		AuthForgotPasswordCmpnt, AuthForgotPasswordDialog,
		AuthResetPasswordCmpnt
	],
	providers: [
		AuthGuard, AuthenticationService
	]
})
export class AuthModule {
}