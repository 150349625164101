import { BaseDto } from "../baseDto"
/* WARLUpdateLogBaseDto generated at 2024-12-06 13:53:49.804 +11:00*/
export class WARLUpdateLogBaseDto extends BaseDto {
	WebARLUpdateLogId?: string;
	Material?: string;
	UpdateDescription?: string;
	ProgramRegion?: string;
	ProgramStatus?: string;
	GoLiveDate?: Date;
	DisplayGoLiveDateText?: string;
	PREPUpdateTimeline?: string;
	ArtworkUpdateTimeline?: string;
	StatusCode?: string;
}
