<div class="page-content-container">
    <div class="flex-wrap">
        <div class="center logo-image">
            <img src="images/ARL_website_logo.png" alt="Logo of the Australasian Recycling Label." class="brand-logo">
        </div>
    </div>
    <div class="flex-wrap" *ngIf="showSignIn">
        <!-- <div class="flex-wrap">
            <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
                <h1>Sign In</h1>
            </div>
        </div> -->
        <div class="flex-wrap mt-2">
            <auth-local-signin name="signInCmpnt" #signInCmpnt class="flex-wrap" [visible]="visible && showComponent"
                (onSuccess)="onLocalSignInSuccessEvent($event)" (onFailed)="onLocalSignInFailedEvent($event)"
                (onClose)="onLocalSignInCancelEvent()"
                (onForgotPassword)="onLocalSignInForgotPassword($event)"></auth-local-signin>
        </div>
    </div>
    <!-- <div class="flex-wrap" *ngIf="showChangePassword">
        <div class="flex-wrap">
            <auth-change-password name="changePwdCmpnt" #changePwdCmpnt class="flex-wrap"
                [(visible)]="showChangePassword" (onSuccess)="onChangePasswordSuccessEvent($event)"
                (onFailed)="onChangePasswordFailedEvent($event)" (onClose)="onCloseChangePasswordEvent($event)"
                [(user)]="user"></auth-change-password>
        </div>
    </div>-->
    <div class="flex-wrap" *ngIf="showForgotPassword">
        <div class="flex-wrap">
            <auth-forgot-password name="forgotPasswordCmpnt" #forgotPasswordCmpnt class="flex-wrap"
                [visible]="showForgotPassword" (onCancel)="onForgotPasswordCancelEvent($event)"
                (onClose)="onForgotPasswordCloseEvent($event)">
            </auth-forgot-password>
        </div>
    </div>
</div>