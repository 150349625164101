export class ApplicationInfo {
    EnvironmentCode?: string;
    TokenValidityInMinutes?: number;
    ApiUrl?: string;
    LoginAction?: string;
    RefreshTokenAction?: string;
    CheckAuthenticationAction?: string;
    LogOutAction?: string;
    SignalService: string = "";
    SignalHubName?: string;
    BasecampSSOUrl?: string;    
    RMUrl?: string;
}