import { BaseDto } from "../baseDto"
/* IpAddressLocationBaseDto generated at 2024-12-06 13:53:49.511 +11:00*/
export class IpAddressLocationBaseDto extends BaseDto {
	IpAddressLocationId?: string;
	IpAddress?: string;
	Hostname?: string;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	DateLastRefreshed?: Date;
	DateLastSeen?: Date;
}
