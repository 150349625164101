import { BaseDto } from "../baseDto"
/* ProjectBaseDto generated at 2024-12-06 13:53:49.640 +11:00*/
export class ProjectBaseDto extends BaseDto {
	ProjectId?: string;
	ProjectVersionNo?: number;
	OrganisationId?: string;
	ContactId?: string;
	SF_AccountId?: string;
	OrganisationName?: string;
	Org_ProjectId?: string;
	Org_StatusCode?: string;
	ARLM_OrganisationId?: string;
	ARLM_ProjectId?: string;
	ARLM_UserId?: string;
	PREP_OrganisationId?: number;
	PREP_ProjectId?: number;
	PREP_UserId?: number;
	PREP_ReportId?: number;
	PREP_ReportDate?: Date;
	ProjectName?: string;
	ProjectDescription?: string;
	Reference?: string;
	Category?: string;
	ProductMarkets?: string;
	IsCDSEligible?: boolean;
	IsSecondaryMaterialProject?: boolean;
	SKU?: string;
	UPC?: string;
	ARLM_StatusCode?: string;
	ARLM_OriStatusCode?: string;
	ProjectStatus?: string;
	ReportStatus?: string;
	IsArchived?: boolean;
	Message?: string;
	IsAddUpdated?: boolean;
	IsDownloaded?: boolean;
	IsFirstDownload?: boolean;
	NoOfSeparableItems?: number;
	ProjectAddedBy?: string;
	ProjectAddedById?: string;
	ProjectDateAdded?: Date;
	ProjectUpdatedBy?: string;
	ProjectUpdatedById?: string;
	ProjectDateUpdated?: Date;
	ActionStatus?: string;
	StatusCode?: string;
}
