import { BaseDto } from "../baseDto"
/* APCOAnnualReportBaseDto generated at 2024-12-06 13:53:49.376 +11:00*/
export class APCOAnnualReportBaseDto extends BaseDto {
	APCOAnnualReportId?: string;
	OrganisationId?: string;
	SubmissionYear?: string;
	DomainTypeName?: string;
	FileURL?: string;
	ReportingPeriod?: string;
	TextData?: string;
	LastErrorMessage?: string;
	StatusCode?: string;
}
