import { BaseDto } from "../baseDto"
/* DbResourceVersionBaseDto generated at 2024-12-06 13:53:49.443 +11:00*/
export class DbResourceVersionBaseDto extends BaseDto {
	DbResourceVersionId?: string;
	SystemResourceId?: string;
	ResourceDbType?: string;
	Version?: number;
	ApplyDate?: Date;
	DateCompleted?: Date;
	UpgradeDbScript?: string;
	StatusCode?: string;
}
