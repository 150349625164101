import { BaseDto } from "../baseDto"
/* ProjectItemGroupBaseDto generated at 2024-12-06 13:53:49.625 +11:00*/
export class ProjectItemGroupBaseDto extends BaseDto {
	ProjectItemGroupId?: string;
	ProjItemGroupName?: string;
	ProjItemGroupDescription?: string;
	ProjectId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	StatusCode?: string;
}
