import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Guid } from '../../model/shared';
import { AppContextService } from '../../service/base/app-context.service';
//import { BaseComponent } from '../../base.component';
//import { AppContextService } from '../../../service';
//import { Guid } from 'src/app/platform/model';
@Component({
    selector: "core-help-info",
    templateUrl: "./help-info.component.html"
})
export class CoreHelpInfoCmpnt extends BaseComponent implements OnInit, OnDestroy {   
    compKey: string = Guid.newGuid();
    isShowHelpInfo: boolean = false;
  @Input() helpMessageHtml: string = "";


  @Input() system: string = "";
  constructor(appCtx: AppContextService, private renderer: Renderer2, private el: ElementRef) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {

          window.removeEventListener('click', this.onWindowClick.bind(this));
          this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {

          window.addEventListener('click', this.onWindowClick.bind(this));
          this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
  }

  onWindowClick(event: MouseEvent)
  {
    if (!this.el.nativeElement.contains(event.target)) {
   
      this.isShowHelpInfo = false;
    }
  }

    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {             
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {            
            this.showComponent = true;
        } catch (ex) {            
            console.error(ex);
        }
    } 
    hideHelpInfo() {
        this.isShowHelpInfo = false;
  }
  handleBubbleClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName === 'A') {
      this.isShowHelpInfo = false;
      return;
    }
    event.stopPropagation();
    this.toggleHelpInfo();
 
  }
  toggleHelpInfo(): void {
    this.isShowHelpInfo = !this.isShowHelpInfo;
  }
}
