import { BaseDto } from "../baseDto"
/* ObjectAttributeVersionBaseDto generated at 2024-12-06 13:53:49.531 +11:00*/
export class ObjectAttributeVersionBaseDto extends BaseDto {
	ObjectAttributeVersionId?: string;
	ObjectAttributeId?: string;
	ObjAttrVersionNo?: number;
	ObjAttrJSON?: string;
	IsUsed?: boolean;
	StatusCode?: string;
}
