<div class="flex-wrap" *ngIf="showComponent && visible">
    <div class="flex-wrap">
        <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
            <b>Login</b>
        </div>
    </div>
    <form name="form" class="flex-wrap" (ngSubmit)="onProcessRequest()" #thisForm="ngForm" autocomplete="off"
        novalidate>
        <div *ngIf="showLogin" class="flex-wrap">
            <div class="flex-wrap mt-2">
                <label>Email</label>
            </div>
            <div class="flex-wrap">
                <input class="flex-wrap" name="username" type="text" [(ngModel)]="user.Username" required pInputText
                    validateEmail />
            </div>
            <div class="flex-wrap mt-3">
                <label>Password </label>
                <i class="fas fa-eye" (click)="showLoginPassword = !showLoginPassword"></i>
            </div>
            <div class="flex-wrap">
                <input class="flex-wrap" name="password" type="{{(showLoginPassword) ? 'text' : 'password'}}"
                    [(ngModel)]="user.Password" required pInputText validatePassword />
            </div>
            <div class="flex-wrap mt-3">
                <div class="flex-wrap p2">
                    <p-button type="submit" class="flex-wrap button button-highlight button-block center"
                        [disabled]="!isValid()">
                        <span class="center">Sign In</span>
                    </p-button>
                </div>
                <div class="flex-wrap p2">
                    <p-button type="button" class="flex-wrap button button-highlight button-block center"
                        (click)="onClickForgotPassword()">
                        <span class="center">Forgot Password</span>
                    </p-button>
                </div>
                <div class="flex-wrap p2">
                    <p-button type="button" class="flex-wrap button button-highlight button-block center"
                        (click)="onCancelRequest()">
                        <span class="center">Cancel</span>
                    </p-button>
                </div>
            </div>
        </div>
    </form>
</div>